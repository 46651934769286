import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        modals: []  // Array to manage multiple modals
    },
    reducers: {
        openModal: (state, action) => {
            const { id, className, title, bodyType, extraObject, size, onClose } = action.payload
            state.modals.push({
                id,
                isOpen: true,
                bodyType,
                className,
                title,
                size: size || 'md',
                extraObject,
                onClose // Store the onClose callback
            })
        },

        closeModal: (state, action) => {
            const { id } = action.payload
            const modal = state.modals.find(modal => modal.id === id)
            if (modal && modal.onClose) {
                modal.onClose() // Invoke the onClose callback
            }
            state.modals = state.modals.filter(modal => modal.id !== id)
        },
    }
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer