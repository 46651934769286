// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "days": {
            "sun": "Sun",
            "mon": "Mon",
            "tue": "Tue",
            "wed": "Wed",
            "thu": "Thu",
            "fri": "Fri",
            "sat": "Sat"
          },
          "No Labels": "No Labels",
          "Check Subtask List": "Check Subtask List",
          "Delete all sub tasks": "Delete all sub tasks",
          "Add Sub Task": "Add Sub Task",
          "Add Label": "Add Label",
          "Due Date": "Due Date",
          "Recurrence": "Recurrence",
          "Recurrence Settings": "Recurrence Settings",
          "Recurrence Type": "Recurrence Type",
          "None": "None",
          "Daily": "Daily",
          "Weekly": "Weekly",
          "Monthly": "Monthly",
          "Yearly": "Yearly",
          "Days After Completion": "Days After Completion",
          "Custom": "Custom",
          "Repeat on date": "Repeat on date",
          "Skip Weekends": "Skip Weekends",
          "Recurring Forever": "Recurring Forever",
          "Repeat Count": "Repeat Count",
          "Times": "Times",
          "Delete Card": "Delete Card",
          "Deadline": "Deadline",
          "dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          "recurrenceOptions": {
            "first": "First",
            "second": "Second",
            "third": "Third",
            "fourth": "Fourth",
            "last": "Last",
            "sameDay": "Same Day Each Month",
            "firstDay": "First Day of the Month",
            "lastDay": "Last Day of the Month",
            "nthInstance": "Monthly on the {{instance}} {{dayName}}", // Using interpolation for dynamic values
          },
          "recurrenceYearlyOptions": {
            "first": "First",
            "second": "Second",
            "third": "Third",
            "fourth": "Fourth",
            "last": "Last",
            "sameDay": "Same Day Each Month",
            "firstDay": "First Day of the Month",
            "lastDay": "Last Day of the Month",
            "nthInstance": "{{instance}} {{dayName}} on {{month}}", // Using interpolation for dynamic values
          },
          // Add more translations as needed
        },
      },
      id: {
        translation: {
          "days": {
            "sun": "Min",
            "mon": "Sen",
            "tue": "Sel",
            "wed": "Rab",
            "thu": "Kam",
            "fri": "Jum",
            "sat": "Sab"
          },
          "No Labels": "Tidak Ada Label",
          "Check Subtask List": "Periksa Daftar",
          "Delete all sub tasks": "Hapus semua",
          "Add Sub Task": "Tambahkan Subtugas",
          "Add Label": "Tambahkan Label",
          "Due Date": "Tanggal Jatuh Tempo",
          "Recurrence": "Pengulangan",
          "Recurrence Settings": "Pengaturan Pengulangan",
          "Recurrence Type": "Jenis Pengulangan",
          "None": "Tidak Ada",
          "Daily": "Harian",
          "Weekly": "Mingguan",
          "Monthly": "Bulanan",
          "Yearly": "Tahunannya",
          "Days After Completion": "Hari Setelah Penyelesaian",
          "Custom": "Kustom",
          "Skip Weekends": "Lewati Akhir Pekan",
          "Recurring Forever": "Berulang Selamanya",
          "Repeat Count": "Jumlah Pengulangan",
          "Times": "Kali",
          "Delete Card": "Hapus Kartu",
          "Deadline": "Batas Waktu",
          "Repeat on date": "Ulangi pada tanggal",
          "dayNames": ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
          "recurrenceOptions": {
            "sameDay": "Hari yang sama setiap bulan",
            "firstDay": "Hari Pertama setiap bulan",
            "lastDay": "Hari Terakhir setiap bulan",
            "first": "pertama",
            "second": "kedua",
            "third": "ketiga",
            "fourth": "keempat",
            "last": "terakhir",
            "nthInstance": "Hari {{dayName}}, minggu {{instance}}", // Using interpolation for dynamic values
          },
          "recurrenceYearlyOptions": {
            "sameDay": "Hari yang sama setiap bulan",
            "firstDay": "Hari Pertama setiap bulan",
            "lastDay": "Hari Terakhir setiap bulan",
            "first": "pertama",
            "second": "kedua",
            "third": "ketiga",
            "fourth": "keempat",
            "last": "terakhir",
            "month": "bulan",
            "nthInstance": "Hari {{dayName}}, minggu {{instance}} bulan {{month}}", // Using interpolation for dynamic values
          },
          // Add more translations as needed
        },
      },
    },
    lng: "en", // Default language
    fallbackLng: "en",
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
