import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

function Privacy() {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate('/');
    }

    return (
        <div className="min-h-screen bg-base-200 flex items-center">
            <div className="card mx-auto w-full max-w-5xl shadow-xl">
                <div className="bg-base-100 rounded-xl">
                    <div className='py-24 px-10'>
                        <h2 className='text-2xl font-semibold mb-2 text-center'>Privacy Policy</h2>
                        <main role="main" className="container">
                            <p>
                                <br /> &ldquo;Our Products&rdquo; includes software Products and services (provided by our company and/or our worldwide branches, affiliates or partners) in connection with the software Products we provide. The software products and applications we provide include [ScanSigned].
                            </p>
                            <p>I. Applicability and content of the Privacy Policy</p>
                            <p>Thank you for choosing to use our products and services. You can use Our Services in various ways. We hope you have a clear understanding of how we collect and use information and what actions you can take to protect your privacy through our Privacy Policy. IF YOU DO NOT AGREE TO OUR PRIVACY POLICY, DO NOT USE OUR PRODUCTS AND SERVICES. IF YOU HAVE STARTED USING OUR PRODUCTS AND SERVICES, PLEASE CANCEL YOUR ACCOUNT IMMEDIATELY AND STOP USE. BREAKING AND USE OF OUR PRODUCTS MEANS YOU ACCEPT THAT THIS PRIVACY POLICY APPLIES TO YOU.</p>
                            <p>Our Privacy Policy offers answers to the following questions: <br /> (1) The purpose of collecting and classifying personal information; <br /> (2) the scope and means of gathering information; <br /> (3) Options we provide to you, including how to access, review and update personal information and cancel your account; <br /> (4) Use and management of personal information; <br /> (5) Sharing, disclosing and providing personal information to the public; <br /> (6) Information security, etc. </p>
                            <p>II. Renew</p>
                            <p>We are constantly improving Our Privacy Policy, and with the expansion of the scope of Our Products and services, we may update our Privacy Policy from time to time. Please review our Privacy Policy regularly to find out in its updated versions. If any update results in a reduction of your rights under the Privacy Policy, we will notify you by posting a prominent reminder or announcement on the interface of Our Products, or emailing you, prior to the date the revised version becomes effective. YOUR CONTINUOUS USE OF OUR PRODUCTS SINCE THAT DAY WILL BE UNDERSTANDED ONCE YOU FULLY ACCEPT THE UPDATED PRIVACY POLICY.</p>
                            <p>IF YOU DO NOT AGREE TO THE REVISED PRIVACY POLICY, PLEASE CANCEL YOUR ACCOUNT IMMEDIATELY AND STOP USING OUR PRODUCTS AND SERVICES.</p>
                            <p>Purpose of collection and classification of personal information</p>
                            <p>1. Purpose of gathering information <br /> We provide services to you through websites, software and other applications. We collect your information only for the purpose of providing you with products and services (the functions designed for each product differ from each other; you should familiarize yourself with these differences and make your own privacy settings based on your needs and circumstances), improve the experience using the Products and our services, and facilitate your wiser use of our products and services. </p>
                            <p>2. Classification of information <br /> The information generated during your use of Our Products and services can be categorized into the following two types based on whether or not the subject of the information can be identified with the information: <br /> (1) Personally identifiable information: mainly includes information that you submit, and can be used to directly identify, track, distinguish and determine your true identity, such as email address and phone number, etc. <br /> (2) Non-identifiable personal information: mainly includes information generated during the use of Our Products and services, and may be related to you but cannot be used to directly identify, track, distinguish, and determine your true identity, such as usage records you our products and services. </p>
                            <p>3. Considerations in the design of the protection of personal information Products <br /> In designing the protection of the Product&#39;s personal information, the following factors are considered: <br /> a) Supply of Products and services; <br /> b) Operating system security settings on user terminal equipment (usually refers to different security settings in Android and iOS systems); </p>
                        </main>
                        <div className="text-center mt-10">
                            <button
                                onClick={handleBackToHome}
                                className="btn btn-primary"
                            >
                                Back to Home
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;